<script setup lang="ts">

const props = defineProps({
  icon_name: null,
});

</script>

<template>
  <div class="icon-container">
    <Icon size="28" class="icon-in" :name="props.icon_name"/>
  </div>
</template>

<style scoped lang="scss">
.icon-container {
  margin-top: 10px;
  background: linear-gradient(180deg, rgba(228, 229, 231, 0.48) 0%, rgba(247, 248, 248, 0) 100%, rgba(228, 229, 231, 0) 100%);
  width: 88px;
  height: 88px;
  padding: 16px;
  gap: 16px;
  border-radius: 96px;
  box-shadow: 0 -1px 1px 0 rgba(58, 62, 89, 0.26);

  .icon-in {
    width: 56px;
    height: 56px;
    padding: 14px;
    gap: 14px;
    border-radius: 96px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(226, 228, 233, 1);
    box-shadow: 0 2px 4px 0 rgba(27, 28, 29, 0.04);
    color: rgba(82, 88, 102, 1);
  }
}

</style>