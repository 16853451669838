import {endpoints} from "~/constants/endpoints.js";
import {$useApiFetch, $useFetch} from "~/composables/useApiFetch";

export const userCreate = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.user_management.create, {
        method: 'POST',
        body: data,
    }).finally(() => isLoading.set(false))
}

export const uploadAvatar = async (data: any) => {
    return await $useApiFetch(endpoints.user_management.avatar, {
        method: 'POST',
        body: data,
    })
}

export const getUserProfile = async () => {
    return await $useApiFetch(endpoints.user_management.get_profile, {
        method: 'GET',
    })
}

export const removeAvatar = async () => {
    return await $useApiFetch(endpoints.user_management.avatar, {
        method: 'DELETE',
    })
}

export const OTPSend = async (data: any) => {
    const isLoading = useIsLoadingStore();
    isLoading.set(true);
    return await $useApiFetch(endpoints.auth.otp.send, {
        method: 'POST',
        body: data
    }).finally(() => isLoading.set(false))
}
